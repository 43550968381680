var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout",
    { attrs: { view: "hHh Lpr fFf" } },
    [
      _c(
        "q-layout-header",
        { attrs: { id: "appHeader" } },
        [
          _c(
            "q-toolbar",
            { attrs: { color: "primary", inverted: "", dir: "ltr" } },
            [
              _c(
                "q-btn",
                {
                  attrs: { size: "1.2em", round: "" },
                  on: { click: _vm.drawerTriggerShow }
                },
                [_c("q-icon", { attrs: { name: "ion-arrow-forward" } })],
                1
              ),
              _c(
                "q-toolbar-title",
                { attrs: { id: "appTitle" } },
                [
                  _vm.authenticated
                    ? [
                        _c(
                          "span",
                          { staticClass: "title uppercase text-weight-bold" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$store.state.app.io.name
                                  ? _vm.$t("HELLO_NAME", {
                                      name: _vm.$store.state.app.io.name.split(
                                        " "
                                      )[0]
                                    })
                                  : _vm.$t("HELLO_FRIEND")
                              )
                            )
                          ]
                        )
                      ]
                    : [
                        _c(
                          "span",
                          { staticClass: "title uppercase text-weight-bold" },
                          [_vm._v(_vm._s(_vm.$t("HELLO_FRIEND")))]
                        )
                      ],
                  _c(
                    "span",
                    {
                      staticClass:
                        "title uppercase text-weight-bold text-attention",
                      attrs: { slot: "subtitle" },
                      slot: "subtitle"
                    },
                    [_vm._v(_vm._s(_vm.$t(_vm.$route.name)))]
                  )
                ],
                2
              ),
              _vm.authenticated && _vm.$route.name === "CHANNEL.LABEL_TT"
                ? _c(
                    "q-btn",
                    {
                      attrs: { round: "", size: "1.2em" },
                      on: { click: _vm.goHome }
                    },
                    [_c("q-icon", { attrs: { name: "ion-close" } })],
                    1
                  )
                : _c("q-btn", {
                    staticClass: "invisible",
                    attrs: { round: "", size: "1.2em" }
                  })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-layout-drawer",
        {
          attrs: { side: "left", behavior: "mobile" },
          model: {
            value: _vm.drawerShow,
            callback: function($$v) {
              _vm.drawerShow = $$v
            },
            expression: "drawerShow"
          }
        },
        [
          _c(
            "q-list",
            {
              staticClass: "text-system-brand text-weight-medium",
              attrs: { highlight: "", "no-border": "", link: "" }
            },
            [
              _c(
                "q-item",
                {
                  nativeOn: {
                    click: function($event) {
                      return _vm.drawerTriggerHide($event)
                    }
                  }
                },
                [
                  _c("q-item-side", [
                    _vm.settings_branding
                      ? _c("img", {
                          staticClass: "non-selectable brand-wordmark",
                          attrs: {
                            width: "118",
                            src:
                              "https://www.expocitydubai.com/_nuxt/img/logo-en@2x.05ed9b9.png",
                            alt: "Expo City Dubai"
                          }
                        })
                      : _c("img", {
                          staticClass: "non-selectable brand-wordmark",
                          attrs: {
                            width: "60",
                            src: "/statics/icons/icon-gold.svg",
                            alt: "wings"
                          }
                        })
                  ]),
                  _c(
                    "q-item-main",
                    [
                      _c("q-item-tile", { attrs: { sublabel: "" } }, [
                        _vm._v("\n             \n          ")
                      ])
                    ],
                    1
                  ),
                  _c("q-item-side", {
                    staticClass: "on-left-lg",
                    attrs: {
                      right: "",
                      icon: "ion-arrow-back",
                      color: "primary"
                    }
                  })
                ],
                1
              ),
              !_vm.isProduction && _vm.authenticated
                ? _c(
                    "q-list-header",
                    { staticClass: "no-margin-top text-weight-bold text-grey" },
                    [_vm._v("Happenings")]
                  )
                : _vm._e(),
              !_vm.isProduction && _vm.authenticated
                ? _c(
                    "q-item",
                    { attrs: { item: "", to: "/today" } },
                    [
                      _c("q-item-side", {
                        staticClass: "text-center",
                        attrs: {
                          color: _vm.anyDarkmode ? "" : "blue-grey-10",
                          icon: "ion-today"
                        }
                      }),
                      _c("q-item-main", {
                        attrs: {
                          label: "Today",
                          sublabel: "Breeze through today"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("q-item-separator"),
              _c(
                "q-list-header",
                { staticClass: "no-margin-top text-weight-bold text-grey" },
                [_vm._v("Services")]
              ),
              !_vm.authenticated
                ? _c(
                    "q-item",
                    {
                      attrs: { link: "", tag: "label", item: "", to: "/login" }
                    },
                    [
                      _c(
                        "q-item-side",
                        { staticClass: "text-center" },
                        [
                          _c("q-icon", {
                            attrs: {
                              name: "ion-log-in",
                              color: _vm.anyDarkmode ? "" : "blue-grey-10",
                              size: "33px"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item-main",
                        [
                          _c(
                            "q-item-tile",
                            {
                              staticClass:
                                "capitalize text-primary text-weight-semibold",
                              attrs: { label: "" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("DRAWER.ITEM.GET_STARTED.L"))
                              )
                            ]
                          ),
                          _c("q-item-tile", { attrs: { sublabel: "" } }, [
                            _vm._v(_vm._s(_vm.$t("DRAWER.ITEM.GET_STARTED.D")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.authenticated
                ? _c(
                    "q-item",
                    { attrs: { item: "", exact: "", to: "/" } },
                    [
                      _c("q-item-side", {
                        staticClass: "text-center",
                        attrs: {
                          color: _vm.anyDarkmode ? "" : "blue-grey-10",
                          icon: "ion-heart"
                        }
                      }),
                      _c("q-item-main", {
                        attrs: {
                          label: "Subscriptions",
                          sublabel: "Your preferred services"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.authenticated && !_vm.isProduction
                ? _c(
                    "q-item",
                    { attrs: { item: "", exact: "", to: "/memberships" } },
                    [
                      _c("q-item-side", {
                        staticClass: "text-center",
                        attrs: {
                          color: _vm.anyDarkmode ? "" : "blue-grey-10",
                          icon: "ion-albums"
                        }
                      }),
                      _c("q-item-main", {
                        attrs: {
                          label: "Memberships",
                          sublabel: "Your paid subscriptions"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.authenticated && !_vm.isProduction
                ? _c(
                    "q-item",
                    { attrs: { item: "", exact: "", to: "/nests" } },
                    [
                      _c("q-item-side", {
                        staticClass: "text-center",
                        attrs: {
                          color: _vm.anyDarkmode ? "" : "blue-grey-10",
                          icon: "ion-apps"
                        }
                      }),
                      _c("q-item-main", {
                        attrs: {
                          label: "Nests",
                          sublabel: "Create public list of channels"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.authenticated && !_vm.isProduction
                ? _c(
                    "q-item",
                    { attrs: { item: "", to: "/notifications" } },
                    [
                      _c("q-item-side", {
                        staticClass: "text-center",
                        attrs: {
                          color: _vm.anyDarkmode ? "" : "blue-grey-10",
                          icon: "ion-notifications"
                        }
                      }),
                      _c("q-item-main", {
                        attrs: {
                          label: "Notifications",
                          sublabel: "Your service status changes"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("q-item-separator"),
              _c(
                "q-list-header",
                { staticClass: "text-weight-bold text-grey" },
                [_vm._v("Businesses")]
              ),
              !_vm.authenticated
                ? _c(
                    "p",
                    {
                      staticClass: "text-grey-10 text-weight-regular",
                      staticStyle: {
                        padding: "20px",
                        "background-color": "rgba(75, 226, 73, 0.15)",
                        margin: "10px",
                        "margin-top": "-10px",
                        "border-radius": "0.5em"
                      }
                    },
                    [
                      _c("span", { staticClass: "block font-size-80p" }, [
                        _c("strong", { staticClass: "text-primary" }, [
                          _vm._v("Ready to go live?")
                        ]),
                        _vm._v(
                          " Setup your business channel in minutes and share features, status, and promotions with your customers. "
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v("Sign up now for a "),
                        _c("strong", { staticClass: "text-empower" }, [
                          _vm._v("free 30-day trial")
                        ]),
                        _vm._v(". No credit card required.")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.authenticated
                ? _c(
                    "q-item",
                    { attrs: { item: "", to: "/business" } },
                    [
                      _c("q-item-side", {
                        staticClass: "text-center",
                        attrs: {
                          color: _vm.anyDarkmode ? "" : "blue-grey-10",
                          icon: "ion-radio"
                        }
                      }),
                      _c("q-item-main", {
                        attrs: {
                          label: "Manage Channels",
                          sublabel: "Your business channels"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.authenticated && !_vm.isProduction
                ? _c(
                    "q-item",
                    { attrs: { item: "" } },
                    [
                      _c("q-item-side", {
                        staticClass: "text-center",
                        attrs: {
                          color: _vm.anyDarkmode ? "" : "blue-grey-10",
                          icon: "ion-code"
                        }
                      }),
                      _c("q-item-main", {
                        attrs: {
                          label: "Integrations",
                          sublabel: "Services apps and devices"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("q-item-separator"),
              _c(
                "q-list-header",
                { staticClass: "no-margin-top text-weight-bold text-grey" },
                [_vm._v("Account")]
              ),
              _c(
                "q-item",
                {
                  attrs: { item: "" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.accountDialogTrigger()
                    }
                  }
                },
                [
                  _c("q-item-side", {
                    staticClass: "text-center",
                    attrs: {
                      color: _vm.anyDarkmode ? "" : "blue-grey-10",
                      icon: "ion-switch"
                    }
                  }),
                  _c("q-item-main", {
                    attrs: {
                      label: "Settings",
                      sublabel: "Preferences and updates"
                    }
                  })
                ],
                1
              ),
              _vm.authenticated
                ? _c(
                    "q-item",
                    { attrs: { item: "", to: "/plans" } },
                    [
                      _c("q-item-side", {
                        staticClass: "text-center",
                        attrs: {
                          color: _vm.anyDarkmode ? "" : "blue-grey-10",
                          icon: "ion-list-box"
                        }
                      }),
                      _c("q-item-main", {
                        attrs: {
                          label: "Plans",
                          sublabel: "Subscriptions and features"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.authenticated
                ? _c(
                    "q-item",
                    {
                      attrs: { item: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.dialogWallet($event)
                        }
                      }
                    },
                    [
                      _c("q-item-side", {
                        staticClass: "text-center",
                        attrs: {
                          color: _vm.anyDarkmode ? "" : "blue-grey-10",
                          icon: "ion-wallet"
                        }
                      }),
                      _c(
                        "q-item-main",
                        [
                          _c("q-item-tile", { attrs: { label: "" } }, [
                            _vm._v("Wallet")
                          ]),
                          _c("q-item-tile", { attrs: { sublabel: "" } }, [
                            _vm._v(
                              _vm._s(_vm.user_details.currency || "AED") +
                                " " +
                                _vm._s(
                                  _vm._f("nformat")(
                                    _vm.user_details.funds / 100,
                                    "0,0.00"
                                  )
                                )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("q-item-separator"),
              _vm.authenticated
                ? _c(
                    "q-list-header",
                    { staticClass: "no-margin-top text-weight-bold text-grey" },
                    [_vm._v("Profile")]
                  )
                : _vm._e(),
              _vm.authenticated
                ? _c(
                    "q-item",
                    {
                      attrs: { item: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.profileDialogTrigger()
                        }
                      }
                    },
                    [
                      _vm.$store.state.app.io.picture
                        ? _c("q-item-side", {
                            staticClass: "text-center",
                            attrs: {
                              color: _vm.anyDarkmode ? "" : "blue-grey-10",
                              avatar: _vm.$store.state.app.io.picture
                            }
                          })
                        : _c("q-item-side", {
                            staticClass: "text-center",
                            attrs: {
                              color: _vm.anyDarkmode ? "" : "blue-grey-10",
                              icon: "ion-contact"
                            }
                          }),
                      _c(
                        "q-item-main",
                        [
                          _vm.user_details.name
                            ? _c(
                                "q-item-tile",
                                {
                                  staticClass: "text-weight-bold",
                                  attrs: { label: "" }
                                },
                                [_vm._v(_vm._s(_vm.user_details.name))]
                              )
                            : _c(
                                "q-item-tile",
                                {
                                  staticClass:
                                    "text-attention font-size-80p text-weight-semibold",
                                  attrs: { label: "" }
                                },
                                [_vm._v("Tap to setup your name")]
                              ),
                          _c("q-item-tile", { attrs: { sublabel: "" } }, [
                            _vm._v(_vm._s(_vm.user_details.email))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("q-item-separator"),
              _c(
                "p",
                {
                  staticClass: "text-grey-5 text-weight-regular",
                  staticStyle: { padding: "20px", "padding-top": "10px" }
                },
                [
                  _c(
                    "strong",
                    { staticClass: "block font-size-80p" },
                    [
                      _vm._v(
                        "Wings v" +
                          _vm._s(_vm.$store.state.app.version) +
                          "\n          "
                      ),
                      !_vm.isProduction
                        ? _c(
                            "q-chip",
                            {
                              staticClass:
                                "q-chip-na q-chip-denser margin-xs-l",
                              attrs: { dense: "" }
                            },
                            [_vm._v("dev")]
                          )
                        : _vm._e(),
                      _vm.isDemoPage
                        ? _c(
                            "q-chip",
                            {
                              staticClass:
                                "q-chip-pro q-chip-denser margin-xs-l",
                              attrs: { dense: "" }
                            },
                            [_vm._v("AI")]
                          )
                        : _vm._e(),
                      _vm.$store.state.app.version_flag
                        ? _c(
                            "q-chip",
                            {
                              staticClass:
                                "q-chip-pro q-chip-denser margin-xs-l",
                              attrs: { dense: "" }
                            },
                            [_vm._v(_vm._s(_vm.$store.state.app.version_flag))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("span", { staticClass: "block font-size-60p on-top-sm" }, [
                    _vm._v(_vm._s(_vm.$store.state.app.version_date))
                  ]),
                  _c("span", { staticClass: "block font-size-60p on-top-xs" }, [
                    _vm._v(
                      "© " +
                        _vm._s(new Date().getFullYear()) +
                        " letsbutterfly, Inc. All rights reserved."
                    )
                  ]),
                  _c(
                    "span",
                    { staticClass: "block font-size-60p on-top-xs" },
                    [
                      _c(
                        "q-btn",
                        {
                          staticClass:
                            "inline-block text-secondary font-size-100p spaced-width",
                          staticStyle: { "margin-left": "-5px" },
                          attrs: { dense: "" },
                          on: { click: _vm.dialogAboutShowOpen }
                        },
                        [
                          _c("span", { staticClass: "text-system-brand" }, [
                            _vm._v("About")
                          ])
                        ]
                      ),
                      _c(
                        "q-btn",
                        {
                          staticClass:
                            "inline-block text-secondary font-size-100p spaced-width",
                          attrs: { dense: "" },
                          on: { click: _vm.dialogLegalTermsShowOpen }
                        },
                        [
                          _c("span", { staticClass: "text-system-brand" }, [
                            _vm._v("Terms of Use")
                          ])
                        ]
                      ),
                      _c(
                        "q-btn",
                        {
                          staticClass:
                            "inline-block text-secondary font-size-100p spaced-width",
                          attrs: { dense: "" },
                          on: { click: _vm.dialogLegalPrivacyShowOpen }
                        },
                        [
                          _c("span", { staticClass: "text-system-brand" }, [
                            _vm._v("Privacy Policy")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-page-container",
        [
          _c(
            "transition",
            {
              attrs: {
                appear: "",
                "enter-active-class": "animated animated400 slideInUp",
                "leave-active-class": "animated animated400 slideOutDown"
              }
            },
            [
              _c("router-view", {
                attrs: {
                  lang: _vm.lang,
                  auth: _vm.auth,
                  authenticated: _vm.authenticated,
                  ecosystem: _vm.ecosystem,
                  anyDarkmode: _vm.anyDarkmode,
                  soundPlay: _vm.soundPlay,
                  soundFade: _vm.soundFade,
                  modalAdapt: _vm.modalAdapt,
                  accountDialogTrigger: _vm.accountDialogTrigger,
                  drawerTriggerShow: _vm.drawerTriggerShow,
                  dialogAboutShowOpen: _vm.dialogAboutShowOpen,
                  isProduction: _vm.isProduction
                },
                on: {
                  startAjaxBar: _vm.onStartAjaxBar,
                  stopAjaxBar: _vm.onStopAjaxBar
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "text-center" }, [
            _vm.settings_branding
              ? _c("img", {
                  staticClass: "non-selectable brand-wordmark",
                  attrs: {
                    width: "118",
                    src:
                      "https://www.expocitydubai.com/_nuxt/img/logo-en@2x.05ed9b9.png",
                    alt: "Expo City Dubai"
                  }
                })
              : _c("img", {
                  staticClass: "non-selectable brand-wordmark",
                  staticStyle: {
                    "margin-top": "60px",
                    "margin-bottom": "10px"
                  },
                  attrs: {
                    width: "60",
                    src: "/statics/icons/icon-gold.svg",
                    alt: "wings"
                  }
                }),
            _c(
              "p",
              {
                staticClass:
                  "font-size-80p text-grey-5 text-weight-regular block",
                staticStyle: { "padding-bottom": "20px" }
              },
              [
                _vm._v(
                  "\n        Wings v" +
                    _vm._s(_vm.$store.state.app.version) +
                    " – " +
                    _vm._s(_vm.$store.state.app.version_date) +
                    "\n        "
                ),
                _c("br"),
                _vm._v(
                  "© " +
                    _vm._s(new Date().getFullYear()) +
                    " letsbutterfly, Inc. All rights reserved.\n      "
                )
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "q-modal",
        {
          staticClass: "appLayer dialog-item",
          attrs: {
            id: "dialogAbout",
            position: "bottom",
            "enter-class": "slideInUp animated800",
            "leave-class": "slideOutDown animated400"
          },
          model: {
            value: _vm.dialogAboutShow,
            callback: function($$v) {
              _vm.dialogAboutShow = $$v
            },
            expression: "dialogAboutShow"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                {
                  directives: [
                    {
                      name: "touch-pan",
                      rawName: "v-touch-pan.vertical.prevent.stop",
                      value: _vm.modalAdapt,
                      expression: "modalAdapt",
                      modifiers: { vertical: true, prevent: true, stop: true }
                    }
                  ],
                  staticClass: "cursor-grab",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c("q-toolbar-title", [
                    _vm._v(_vm._s(_vm.$t("DRAWER.ITEM.ABOUT.L")))
                  ])
                ],
                1
              ),
              _c(
                "q-toolbar",
                {
                  staticClass: "toolbar-overscroll-shadow",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c(
                    "q-card",
                    {
                      staticClass:
                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                    },
                    [
                      _c(
                        "q-card-main",
                        { staticClass: "column justify-center full-height" },
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass:
                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                              attrs: {
                                color: "primary-light",
                                "text-color": "primary",
                                rounded: ""
                              },
                              on: { click: _vm.dialogAboutShowClose }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/statics/_demo/" +
                                    (_vm.anyDarkmode
                                      ? "chevron.compact.down_white.svg"
                                      : "chevron.compact.down_primary.svg"),
                                  height: "10"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "layout-padding text-center items-center" },
                [
                  _c("q-scroll-observable", {
                    on: { scroll: _vm.toolbarShadowOnOverscroll }
                  }),
                  _c("img", {
                    staticClass: "non-selectable brand-wordmark",
                    staticStyle: { "margin-top": "-50px" },
                    attrs: {
                      width: "60",
                      src: "/statics/icons/icon-gold.svg",
                      alt: "wings"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "content-wrapper text-system-brand",
                      staticStyle: { "margin-top": "-20px" }
                    },
                    [
                      _c("h2", { staticClass: "text-center" }, [
                        _vm._v("Welcome to Wings")
                      ]),
                      _c("p", { staticClass: "text-center text-primary" }, [
                        _c("strong", [
                          _vm._v("The future of service communication")
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "At Wings, we believe that seamless communication is the key to enhancing experiences in the services industry. Our mission is to revolutionize in-person service communication by digitizing and streamlining the way businesses connect with their customers. With our real-time, bi-directional platform, businesses can effortlessly convey the up-to-the-minute status of their amenities, services, and offers. By replacing traditional analog methods with digital channels, we empower businesses to boost efficiency, increase customer satisfaction, and build stronger relationships."
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "Join Wings today and discover a new era of efficient and personalized service communication."
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "20px 20px",
                            background: "#EEEEEE",
                            "border-radius": "1em"
                          }
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass: "text-hint",
                              staticStyle: { "margin-top": "-10px" }
                            },
                            [
                              _c("q-icon", {
                                staticClass: "text-hint",
                                staticStyle: { "font-size": "33px" },
                                attrs: { name: "ion-alert" }
                              }),
                              _vm._v(" beta")
                            ],
                            1
                          ),
                          _vm._v(
                            "\n            Our beta version of Wings is here to provide you with an early glimpse into the future of service communication. As we continue to refine and enhance our platform, your valuable feedback will shape the evolution of our product.\n          "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-modal",
        {
          staticClass: "appLayer dialog-item",
          attrs: {
            id: "dialogLegalTerms",
            position: "bottom",
            "enter-class": "slideInUp animated800",
            "leave-class": "slideOutDown animated400"
          },
          model: {
            value: _vm.dialogLegalTermsShow,
            callback: function($$v) {
              _vm.dialogLegalTermsShow = $$v
            },
            expression: "dialogLegalTermsShow"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                {
                  directives: [
                    {
                      name: "touch-pan",
                      rawName: "v-touch-pan.vertical.prevent.stop",
                      value: _vm.modalAdapt,
                      expression: "modalAdapt",
                      modifiers: { vertical: true, prevent: true, stop: true }
                    }
                  ],
                  staticClass: "cursor-grab",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c("q-toolbar-title", [
                    _vm._v(_vm._s(_vm.$t("DRAWER.ITEM.TERMS.L")))
                  ])
                ],
                1
              ),
              _c(
                "q-toolbar",
                {
                  staticClass: "toolbar-overscroll-shadow",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c(
                    "q-card",
                    {
                      staticClass:
                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                    },
                    [
                      _c(
                        "q-card-main",
                        { staticClass: "column justify-center full-height" },
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass:
                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                              attrs: {
                                color: "primary-light",
                                "text-color": "primary",
                                rounded: ""
                              },
                              on: { click: _vm.dialogLegalTermsShowClose }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/statics/_demo/" +
                                    (_vm.anyDarkmode
                                      ? "chevron.compact.down_white.svg"
                                      : "chevron.compact.down_primary.svg"),
                                  height: "10"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "layout-padding items-center" },
                [
                  _c("q-scroll-observable", {
                    on: { scroll: _vm.toolbarShadowOnOverscroll }
                  }),
                  _c("div", {
                    staticClass: "legal-wrapper text-system-brand",
                    domProps: {
                      innerHTML: _vm._s(_vm.statics.staticsDocsLegalTerms)
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-modal",
        {
          staticClass: "appLayer dialog-item",
          attrs: {
            id: "dialogLegalPrivacy",
            position: "bottom",
            "enter-class": "slideInUp animated800",
            "leave-class": "slideOutDown animated400"
          },
          model: {
            value: _vm.dialogLegalPrivacyShow,
            callback: function($$v) {
              _vm.dialogLegalPrivacyShow = $$v
            },
            expression: "dialogLegalPrivacyShow"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                {
                  directives: [
                    {
                      name: "touch-pan",
                      rawName: "v-touch-pan.vertical.prevent.stop",
                      value: _vm.modalAdapt,
                      expression: "modalAdapt",
                      modifiers: { vertical: true, prevent: true, stop: true }
                    }
                  ],
                  staticClass: "cursor-grab",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c("q-toolbar-title", [
                    _vm._v(_vm._s(_vm.$t("DRAWER.ITEM.PRIVACY.L")))
                  ])
                ],
                1
              ),
              _c(
                "q-toolbar",
                {
                  staticClass: "toolbar-overscroll-shadow",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c(
                    "q-card",
                    {
                      staticClass:
                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                    },
                    [
                      _c(
                        "q-card-main",
                        { staticClass: "column justify-center full-height" },
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass:
                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                              attrs: {
                                color: "primary-light",
                                "text-color": "primary",
                                rounded: ""
                              },
                              on: { click: _vm.dialogLegalPrivacyShowClose }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/statics/_demo/" +
                                    (_vm.anyDarkmode
                                      ? "chevron.compact.down_white.svg"
                                      : "chevron.compact.down_primary.svg"),
                                  height: "10"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "layout-padding text-center items-center" },
                [
                  _c("q-scroll-observable", {
                    on: { scroll: _vm.toolbarShadowOnOverscroll }
                  }),
                  _c("div", {
                    staticClass: "legal-wrapper text-system-brand",
                    domProps: {
                      innerHTML: _vm._s(_vm.statics.staticsDocsLegalPrivacy)
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-modal",
        {
          staticClass: "appLayer dialog-item",
          attrs: {
            id: "dialogProfile",
            position: "bottom",
            "enter-class": "slideInUp animated800",
            "leave-class": "slideOutDown animated400"
          },
          model: {
            value: _vm.dialogProfileShow,
            callback: function($$v) {
              _vm.dialogProfileShow = $$v
            },
            expression: "dialogProfileShow"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                {
                  directives: [
                    {
                      name: "touch-pan",
                      rawName: "v-touch-pan.vertical.prevent.stop",
                      value: _vm.modalAdapt,
                      expression: "modalAdapt",
                      modifiers: { vertical: true, prevent: true, stop: true }
                    }
                  ],
                  staticClass: "cursor-grab",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [_c("q-toolbar-title", [_vm._v(_vm._s(_vm.$t("PROFILE")))])],
                1
              ),
              _c(
                "q-toolbar",
                {
                  staticClass: "toolbar-overscroll-shadow",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c(
                    "q-card",
                    {
                      staticClass:
                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                    },
                    [
                      _c(
                        "q-card-main",
                        { staticClass: "column justify-center full-height" },
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass:
                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                              attrs: {
                                color: "primary-light",
                                "text-color": "primary",
                                rounded: ""
                              },
                              nativeOn: {
                                click: function($event) {
                                  _vm.dialogProfileShow = false
                                  _vm.soundPlay("tap")
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/statics/_demo/" +
                                    (_vm.anyDarkmode
                                      ? "chevron.compact.down_white.svg"
                                      : "chevron.compact.down_primary.svg"),
                                  height: "10"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.authenticated
                ? _c(
                    "div",
                    {
                      staticClass:
                        "q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center"
                    },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Profile")]
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  _vm.dialogAccountShowNameEdit = true
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-contact",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Name")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _vm.dialogAccountShowNameEdit
                                    ? _c("q-input", {
                                        staticClass:
                                          "text-weight-semibold text-black",
                                        staticStyle: { width: "180px" },
                                        attrs: {
                                          autofocus: "",
                                          placeholder: "Enter your first name",
                                          readonly:
                                            _vm.dialogAccountShowNameEditProcessing,
                                          disabled:
                                            _vm.dialogAccountShowNameEditProcessing,
                                          loading:
                                            _vm.dialogAccountShowNameEditProcessing
                                        },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.accountUpdateName($event)
                                          },
                                          blur: _vm.accountUpdateName,
                                          change: _vm.accountUpdateName
                                        },
                                        model: {
                                          value: _vm.$store.state.app.io.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$store.state.app.io,
                                              "name",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "$store.state.app.io.name"
                                        }
                                      })
                                    : _vm.$store.state.app.io.name
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-weight-semibold text-black"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$store.state.app.io.name)
                                          )
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-weight-semibold text-attention"
                                        },
                                        [_vm._v("Not Set")]
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.authenticated
                            ? _c(
                                "q-item",
                                { attrs: { link: "", tag: "label" } },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-at",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "capitalize",
                                          attrs: { label: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Email\n              "
                                          )
                                        ]
                                      ),
                                      _vm.$store.state.app.io.email
                                        ? _c(
                                            "q-item-tile",
                                            {
                                              class: _vm.$store.state.app.io
                                                .email_verified
                                                ? "text-educate"
                                                : "text-attention",
                                              attrs: { sublabel: "" }
                                            },
                                            [
                                              _vm.$store.state.app.io
                                                .email_verified
                                                ? [_vm._v("Verified")]
                                                : [
                                                    _vm._v(
                                                      "\n                  Not verified\n                "
                                                    )
                                                  ]
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    {
                                      staticClass: "disable",
                                      attrs: {
                                        right: "",
                                        disabled: "",
                                        disable: ""
                                      }
                                    },
                                    [
                                      _vm.$store.state.app.io.email
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-weight-semibold text-black"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$store.state.app.io.email
                                                )
                                              )
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-weight-semibold text-attention"
                                            },
                                            [_vm._v("Not Set")]
                                          )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Account")]
                          ),
                          _vm.authenticated
                            ? _c(
                                "q-item",
                                {
                                  attrs: { link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.wingsIDShowOpen($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-lock",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "capitalize",
                                          attrs: { label: "" }
                                        },
                                        [_vm._v("Wings ID")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "text-purple-l2",
                                          attrs: { sublabel: "" }
                                        },
                                        [_vm._v("Blockchain (ETH) verified")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "block text-fixedwidth-brand font-size-70p text-tertiary"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$store.state.app.io.idx.split(
                                                ":"
                                              )[2]
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.authenticated
                            ? _c(
                                "q-item",
                                {
                                  attrs: { link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.dialogLogout()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-log-out",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass:
                                            "capitalize text-protect",
                                          attrs: { label: "" }
                                        },
                                        [_vm._v("Logout")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [_vm._v("Sign out of your account")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-modal",
        {
          staticClass: "appLayer dialog-item",
          attrs: {
            id: "dialogAccount",
            position: "bottom",
            "enter-class": "slideInUp animated800",
            "leave-class": "slideOutDown animated400"
          },
          model: {
            value: _vm.dialogAccountShow,
            callback: function($$v) {
              _vm.dialogAccountShow = $$v
            },
            expression: "dialogAccountShow"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                {
                  directives: [
                    {
                      name: "touch-pan",
                      rawName: "v-touch-pan.vertical.prevent.stop",
                      value: _vm.modalAdapt,
                      expression: "modalAdapt",
                      modifiers: { vertical: true, prevent: true, stop: true }
                    }
                  ],
                  staticClass: "cursor-grab",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [_c("q-toolbar-title", [_vm._v(_vm._s(_vm.$t("SETTINGS")))])],
                1
              ),
              _c(
                "q-toolbar",
                {
                  staticClass: "toolbar-overscroll-shadow",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c(
                    "q-card",
                    {
                      staticClass:
                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                    },
                    [
                      _c(
                        "q-card-main",
                        { staticClass: "column justify-center full-height" },
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass:
                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                              attrs: {
                                color: "primary-light",
                                "text-color": "primary",
                                rounded: ""
                              },
                              nativeOn: {
                                click: function($event) {
                                  _vm.dialogAccountShow = false
                                  _vm.soundPlay("tap")
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/statics/_demo/" +
                                    (_vm.anyDarkmode
                                      ? "chevron.compact.down_white.svg"
                                      : "chevron.compact.down_primary.svg"),
                                  height: "10"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center"
                },
                [
                  _c("q-scroll-observable", {
                    on: { scroll: _vm.toolbarShadowOnOverscroll }
                  }),
                  _c(
                    "q-list",
                    {
                      staticClass:
                        "card text-system-brand text-weight-medium full-width on-top-lg",
                      attrs: { "no-border": "", link: "" }
                    },
                    [
                      _c(
                        "q-list-header",
                        { staticClass: "text-weight-bold text-grey text-left" },
                        [_vm._v("Experience")]
                      ),
                      _c(
                        "q-item",
                        { attrs: { link: "", tag: "label" } },
                        [
                          _c(
                            "q-item-side",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    mode: "out-in",
                                    appear: "",
                                    "enter-active-class":
                                      "animated400 bounceIn",
                                    "leave-active-class":
                                      "animated400 bounceOut"
                                  }
                                },
                                [
                                  _vm.settings_display_effects
                                    ? _c("q-icon", {
                                        key: "settings-effects-on",
                                        attrs: {
                                          name: "ion-eye",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    : _c("q-icon", {
                                        key: "settings-effects-off",
                                        attrs: {
                                          name: "ion-eye-off",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item-main",
                            [
                              _c(
                                "q-item-tile",
                                {
                                  staticClass: "capitalize",
                                  attrs: { label: "" }
                                },
                                [_vm._v("Effects")]
                              ),
                              _c("q-item-tile", { attrs: { sublabel: "" } }, [
                                _vm._v("Shadows, blur, and motion effects")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-item-side",
                            { attrs: { right: "" } },
                            [
                              _c("q-toggle", {
                                attrs: { dark: _vm.anyDarkmode },
                                model: {
                                  value: _vm.settings_display_effects,
                                  callback: function($$v) {
                                    _vm.settings_display_effects = $$v
                                  },
                                  expression: "settings_display_effects"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        { attrs: { link: "", tag: "label" } },
                        [
                          _c(
                            "q-item-side",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    mode: "out-in",
                                    appear: "",
                                    "enter-active-class":
                                      "animated400 bounceIn",
                                    "leave-active-class":
                                      "animated400 bounceOut"
                                  }
                                },
                                [
                                  _vm.settings_sound
                                    ? _c("q-icon", {
                                        key: "settings-sound-on",
                                        attrs: {
                                          name: "ion-volume-high",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    : _c("q-icon", {
                                        key: "settings-sound-off",
                                        attrs: {
                                          name: "ion-volume-off",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item-main",
                            [
                              _c(
                                "q-item-tile",
                                {
                                  staticClass: "capitalize",
                                  attrs: { label: "" }
                                },
                                [_vm._v("Sounds")]
                              ),
                              _c("q-item-tile", { attrs: { sublabel: "" } }, [
                                _vm._v("Immersive accent sounds")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-item-side",
                            { attrs: { right: "" } },
                            [
                              _c("q-toggle", {
                                attrs: { dark: _vm.anyDarkmode },
                                model: {
                                  value: _vm.settings_sound,
                                  callback: function($$v) {
                                    _vm.settings_sound = $$v
                                  },
                                  expression: "settings_sound"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.isDemoPage
                        ? _c(
                            "q-item",
                            { attrs: { link: "", tag: "label" } },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        mode: "out-in",
                                        appear: "",
                                        "enter-active-class":
                                          "animated400 bounceIn",
                                        "leave-active-class":
                                          "animated400 bounceOut"
                                      }
                                    },
                                    [
                                      _vm.settings_voice
                                        ? _c("q-icon", {
                                            key: "settings-voice-on",
                                            attrs: {
                                              name: "ion-mic",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                        : _c("q-icon", {
                                            key: "settings-voice-off",
                                            attrs: {
                                              name: "ion-mic-off",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [
                                      _vm._v("Voice A.I. "),
                                      _c(
                                        "q-chip",
                                        {
                                          staticClass:
                                            "q-chip-pro q-chip-denser",
                                          attrs: { dense: "" }
                                        },
                                        [_vm._v("BETA")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Enable voice-driven interface")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-toggle", {
                                    attrs: { dark: _vm.anyDarkmode },
                                    model: {
                                      value: _vm.settings_voice,
                                      callback: function($$v) {
                                        _vm.settings_voice = $$v
                                      },
                                      expression: "settings_voice"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "q-list",
                    {
                      staticClass:
                        "card text-system-brand text-weight-medium full-width on-top-lg",
                      attrs: { "no-border": "", link: "" }
                    },
                    [
                      _c(
                        "q-list-header",
                        { staticClass: "text-weight-bold text-grey text-left" },
                        [_vm._v("Application")]
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "", tag: "label" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.updateCheck($event)
                            }
                          }
                        },
                        [
                          _c(
                            "q-item-side",
                            { staticClass: "text-center" },
                            [
                              _c("q-icon", {
                                attrs: {
                                  name: "ion-cloud-download",
                                  color: _vm.anyDarkmode ? "" : "blue-grey-10",
                                  size: "33px"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "q-item-main",
                            [
                              _c(
                                "q-item-tile",
                                {
                                  staticClass: "capitalize",
                                  attrs: { label: "" }
                                },
                                [_vm._v("Version")]
                              ),
                              _c("q-item-tile", { attrs: { sublabel: "" } }, [
                                _vm._v("Download latest updates")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-item-side",
                            { attrs: { right: "" } },
                            [
                              _c(
                                "q-item-tile",
                                {
                                  staticClass:
                                    "text-black text-weight-semibold",
                                  attrs: { label: "" }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$store.state.app.version) +
                                      "\n                "
                                  ),
                                  !_vm.isProduction
                                    ? _c(
                                        "q-chip",
                                        {
                                          staticClass:
                                            "q-chip-na q-chip-denser margin-xs-l",
                                          attrs: { dense: "" }
                                        },
                                        [_vm._v("dev")]
                                      )
                                    : _vm._e(),
                                  _vm.isDemoPage
                                    ? _c(
                                        "q-chip",
                                        {
                                          staticClass:
                                            "q-chip-pro q-chip-denser margin-xs-l",
                                          attrs: { dense: "" }
                                        },
                                        [_vm._v("AI")]
                                      )
                                    : _vm._e(),
                                  _vm.$store.state.app.version_flag
                                    ? _c(
                                        "q-chip",
                                        {
                                          staticClass:
                                            "q-chip-pro q-chip-denser margin-xs-l",
                                          attrs: { dense: "" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$store.state.app.version_flag
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("q-chip", {
                                    staticClass: "hidden",
                                    attrs: { dense: "" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-tile",
                                { attrs: { sublabel: "" } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$store.state.app.version_date
                                      ) +
                                      "\n                "
                                  ),
                                  _c(
                                    "q-chip",
                                    {
                                      staticClass: "uppercase",
                                      attrs: { square: "", dense: "" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$store.state.app.version_build
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.isProduction
                    ? _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Developer Debug")]
                          ),
                          _c(
                            "q-item",
                            { attrs: { link: "", tag: "label" } },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        mode: "out-in",
                                        appear: "",
                                        "enter-active-class":
                                          "animated400 bounceIn",
                                        "leave-active-class":
                                          "animated400 bounceOut"
                                      }
                                    },
                                    [
                                      _vm.settings_branding
                                        ? _c("img", {
                                            key: "settings-branding-on",
                                            attrs: {
                                              src:
                                                "/statics/_demo/ecd-icon-on.svg",
                                              width: "33",
                                              height: "33"
                                            }
                                          })
                                        : _c("img", {
                                            key: "settings-branding-off",
                                            attrs: {
                                              src:
                                                "/statics/_demo/ecd-icon-off.svg",
                                              width: "33",
                                              height: "33"
                                            }
                                          })
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Expo City Dubai")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _vm._v(
                                        "Whitelabel to Expo City Dubai branding"
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-toggle", {
                                    attrs: { dark: _vm.anyDarkmode },
                                    model: {
                                      value: _vm.settings_branding,
                                      callback: function($$v) {
                                        _vm.settings_branding = $$v
                                      },
                                      expression: "settings_branding"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            { attrs: { link: "", tag: "label" } },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        mode: "out-in",
                                        appear: "",
                                        "enter-active-class":
                                          "animated400 bounceIn",
                                        "leave-active-class":
                                          "animated400 bounceOut"
                                      }
                                    },
                                    [
                                      _vm.settings_dev_presense
                                        ? _c("q-icon", {
                                            key: "settings-dev-presense-on",
                                            attrs: {
                                              name: "ion-code-working",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                        : _c("q-icon", {
                                            key: "settings-dev-presense-off",
                                            attrs: {
                                              name: "ion-code",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Channel Presense")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Display Presense Module")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-toggle", {
                                    attrs: { dark: _vm.anyDarkmode },
                                    model: {
                                      value: _vm.settings_dev_presense,
                                      callback: function($$v) {
                                        _vm.settings_dev_presense = $$v
                                      },
                                      expression: "settings_dev_presense"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-modal",
        {
          staticClass: "appLayer dialog-item",
          attrs: {
            id: "dialogWallet",
            position: "bottom",
            "enter-class": "slideInUp animated800",
            "leave-class": "slideOutDown animated400"
          },
          model: {
            value: _vm.dialogWalletShow,
            callback: function($$v) {
              _vm.dialogWalletShow = $$v
            },
            expression: "dialogWalletShow"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                {
                  directives: [
                    {
                      name: "touch-pan",
                      rawName: "v-touch-pan.vertical.prevent.stop",
                      value: _vm.modalAdapt,
                      expression: "modalAdapt",
                      modifiers: { vertical: true, prevent: true, stop: true }
                    }
                  ],
                  staticClass: "cursor-grab",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c("q-toolbar-title", [
                    _vm._v(_vm._s(_vm.$t("DRAWER.ITEM.WALLET.L")))
                  ])
                ],
                1
              ),
              _c(
                "q-toolbar",
                {
                  staticClass: "toolbar-overscroll-shadow",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c(
                    "q-card",
                    {
                      staticClass:
                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                    },
                    [
                      _c(
                        "q-card-main",
                        { staticClass: "column justify-center full-height" },
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass:
                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                              attrs: {
                                color: "primary-light",
                                "text-color": "primary",
                                rounded: ""
                              },
                              nativeOn: {
                                click: function($event) {
                                  _vm.dialogWalletShow = false
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/statics/_demo/" +
                                    (_vm.anyDarkmode
                                      ? "chevron.compact.down_white.svg"
                                      : "chevron.compact.down_primary.svg"),
                                  height: "10"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center"
                },
                [
                  _c("q-scroll-observable", {
                    on: { scroll: _vm.toolbarShadowOnOverscroll }
                  }),
                  _c(
                    "q-list",
                    {
                      staticClass:
                        "card text-system-brand text-weight-medium full-width on-top-lg",
                      attrs: { "no-border": "" }
                    },
                    [
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-main",
                            {
                              staticClass:
                                "text-center font-size-180p text-weight-bold"
                            },
                            [
                              _c("small", { staticClass: "text-grey" }, [
                                _vm._v(
                                  _vm._s(_vm.user_details.currency || "AED")
                                )
                              ]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("nformat")(
                                      _vm.user_details.funds / 100,
                                      "0,0.00"
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("q-btn", {
                                staticClass:
                                  "inline-block text-secondary spaced-width",
                                attrs: {
                                  icon: "ion-refresh",
                                  loading: _vm.fundsRefreshing,
                                  dense: ""
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.fundsRefresh($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass:
                                "margin-auto-lr full-height text-system-brand text-weight-semibold uppercase",
                              staticStyle: { width: "80%" },
                              attrs: {
                                loading: _vm.stripePaymentRequest !== null,
                                color: "secondary",
                                "text-color": "white"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.fundSelector($event)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "font-size-100p text-system-brand"
                                },
                                [_vm._v("Add Funds to Wallet")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass:
                                "margin-auto-lr full-height text-system-brand text-weight-semibold uppercase",
                              staticStyle: { width: "80%" },
                              attrs: {
                                loading: _vm.stripePaymentRequest !== null,
                                disabled: _vm.stripePaymentLink === null,
                                color:
                                  _vm.stripePaymentLink === null
                                    ? "grey"
                                    : "primary",
                                "text-color": "white",
                                "icon-right": "ion-open"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openURL(_vm.stripePaymentLink)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "font-size-100p text-system-brand"
                                },
                                [
                                  _vm._v(
                                    "\n                Pay " +
                                      _vm._s(
                                        _vm._f("nformat")(
                                          _vm.stripePaymentAmount / 100,
                                          "0,0.00"
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.user_details.currency || "AED"
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("q-item-separator"),
                      _c(
                        "q-list-header",
                        { staticClass: "text-weight-bold text-grey text-left" },
                        [_vm._v("Localization")]
                      ),
                      _c(
                        "q-item",
                        { attrs: { tag: "label", disabled: "", disable: "" } },
                        [
                          _c(
                            "q-item-side",
                            { staticClass: "text-center" },
                            [
                              _c("q-icon", {
                                attrs: {
                                  name: "ion-cash",
                                  color: _vm.anyDarkmode ? "" : "blue-grey-10",
                                  size: "33px"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "q-item-main",
                            [
                              _c(
                                "q-item-tile",
                                {
                                  staticClass: "capitalize",
                                  attrs: { label: "" }
                                },
                                [_vm._v("Currency")]
                              ),
                              _c("q-item-tile", { attrs: { sublabel: "" } }, [
                                _vm._v("Unit for pricing")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-item-side",
                            { attrs: { right: "" } },
                            [
                              _c("q-item-tile", { attrs: { label: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.user_details.currency || "AED")
                                )
                              ]),
                              _c(
                                "q-item-tile",
                                {
                                  staticClass: "capitalize",
                                  attrs: { sublabel: "" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getCurrencyName(
                                        _vm.user_details.currency
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-modal",
        {
          staticClass: "appLayer",
          attrs: { position: "bottom" },
          model: {
            value: _vm.dialogProfilesShow,
            callback: function($$v) {
              _vm.dialogProfilesShow = $$v
            },
            expression: "dialogProfilesShow"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                { attrs: { slot: "header", inverted: "" }, slot: "header" },
                [
                  _c("q-toolbar-title", [
                    _vm._v(_vm._s(_vm.$t("DRAWER.ITEM.PROFILES.L")))
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "layout-padding" },
                [
                  _c(
                    "q-list",
                    {
                      staticClass: "list-card-options",
                      attrs: {
                        "no-border": "",
                        link: "",
                        dark: _vm.anyDarkmode
                      }
                    },
                    _vm._l(["homes", "animals", "foods", "business"], function(
                      ecoId
                    ) {
                      return _c(
                        "q-item",
                        {
                          key: "profile-item-" + ecoId,
                          attrs: { tag: "label" }
                        },
                        [
                          _c(
                            "q-item-side",
                            { staticClass: "list-card-options-option" },
                            [
                              _c("q-radio", {
                                attrs: { val: ecoId },
                                model: {
                                  value: _vm.ecosystem_id,
                                  callback: function($$v) {
                                    _vm.ecosystem_id = $$v
                                  },
                                  expression: "ecosystem_id"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "q-item-main",
                            { staticClass: "list-card-options-card" },
                            [
                              _c(
                                "q-item-tile",
                                {
                                  staticClass:
                                    "text-family-brand text-weight-medium",
                                  attrs: { label: "" }
                                },
                                [
                                  _c(
                                    "q-card",
                                    {
                                      class: {
                                        unselected: ecoId !== _vm.ecosystem_id
                                      }
                                    },
                                    [
                                      _c(
                                        "q-card-title",
                                        { staticClass: "text-center" },
                                        [
                                          _c("img", {
                                            staticClass: "dark-img-hero",
                                            staticStyle: {
                                              display: "block",
                                              margin: "0 auto",
                                              "margin-bottom": "-8px"
                                            },
                                            attrs: {
                                              width: "38",
                                              src:
                                                "statics/ecosystems/" +
                                                ecoId +
                                                "/icon.svg"
                                            }
                                          }),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(ecoId) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item-main",
                            { staticClass: "list-card-options-description" },
                            [
                              _c(
                                "q-item-tile",
                                {
                                  staticClass: "text-family-brand",
                                  attrs: { label: "" }
                                },
                                [_vm._v("Manage your " + _vm._s(ecoId))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "q-toolbar",
                { attrs: { slot: "footer", inverted: "" }, slot: "footer" },
                [
                  _c(
                    "q-btn",
                    {
                      directives: [{ name: "ripple", rawName: "v-ripple" }],
                      staticClass: "shadow-24 full-width",
                      attrs: { size: "lg", push: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.dialogProfilesShow = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("DONE")) + "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-modal",
        {
          staticClass: "appLayer dialog-item",
          attrs: { position: "bottom" },
          model: {
            value: _vm.dialogPreferencesShow,
            callback: function($$v) {
              _vm.dialogPreferencesShow = $$v
            },
            expression: "dialogPreferencesShow"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                { attrs: { slot: "header", inverted: "" }, slot: "header" },
                [
                  _c("q-toolbar-title", [
                    _vm._v(_vm._s(_vm.$t("PERSONALIZE.LABEL_TT")))
                  ])
                ],
                1
              ),
              _c(
                "q-toolbar",
                {
                  staticClass: "toolbar-overscroll-shadow",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c(
                    "q-card",
                    {
                      staticClass:
                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                    },
                    [
                      _c(
                        "q-card-main",
                        { staticClass: "column justify-center full-height" },
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass:
                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                              attrs: {
                                color: "primary-light",
                                "text-color": "primary",
                                rounded: ""
                              },
                              nativeOn: {
                                click: function($event) {
                                  _vm.dialogPreferencesShow = false
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/statics/_demo/" +
                                    (_vm.anyDarkmode
                                      ? "chevron.compact.down_white.svg"
                                      : "chevron.compact.down_primary.svg"),
                                  height: "10"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "layout-padding" },
                [
                  _c("q-scroll-observable", {
                    on: { scroll: _vm.toolbarShadowOnOverscroll }
                  }),
                  _c("q-select", {
                    staticClass: "text-family-brand",
                    attrs: {
                      "no-border": "",
                      "stack-label": _vm.$t("LANGUAGE"),
                      options: [
                        {
                          className: "text-family-brand",
                          label:
                            "English &nbsp; <span class=text-faded>— US</span>",
                          value: "enUS"
                        },
                        {
                          className: "text-family-brand",
                          label:
                            "Español &nbsp; <span class=text-faded>— Spanish</span>",
                          value: "esUS"
                        },
                        {
                          className: "text-family-brand",
                          label:
                            "中文 &nbsp; <span class=text-faded>— Chinese Simplified</span>",
                          value: "zhCN"
                        },
                        {
                          className: "text-family-brand",
                          label:
                            "عربي &nbsp; <span class=text-faded>— Arabic</span>",
                          value: "arSA"
                        }
                      ],
                      dark: _vm.anyDarkmode
                    },
                    model: {
                      value: _vm.lang,
                      callback: function($$v) {
                        _vm.lang = $$v
                      },
                      expression: "lang"
                    }
                  }),
                  _c(
                    "q-list",
                    {
                      staticClass: "text-family-brand hidden",
                      attrs: {
                        "no-border": "",
                        link: "",
                        dark: _vm.anyDarkmode
                      }
                    },
                    [
                      _c("br"),
                      _c(
                        "p",
                        {
                          staticClass: "q-caption no-margin",
                          staticStyle: { color: "#979797" }
                        },
                        [_vm._v(_vm._s(_vm.$t("APPEARANCE")))]
                      ),
                      _c(
                        "q-item",
                        { attrs: { tag: "label" } },
                        [
                          _c(
                            "q-item-main",
                            [
                              _c("q-item-tile", { attrs: { label: "" } }, [
                                _vm._v(_vm._s(_vm.$t("APPEARANCE_DARKMODE")))
                              ]),
                              _c(
                                "q-item-tile",
                                { attrs: { sublabel: "", lines: "2" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("APPEARANCE_DARKMODE_INFO"))
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item-side",
                            { attrs: { right: "" } },
                            [
                              _c("q-toggle", {
                                attrs: { disable: _vm.deviceDarkmode },
                                model: {
                                  value: _vm.userDarkmode,
                                  callback: function($$v) {
                                    _vm.userDarkmode = $$v
                                  },
                                  expression: "userDarkmode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-modal",
        {
          staticClass: "dialog-item",
          attrs: {
            id: "dialogWingsID",
            position: "bottom",
            "content-css": { maxWidth: "400px" },
            "enter-class": "slideInUp animated800",
            "leave-class": "slideOutDown animated400"
          },
          model: {
            value: _vm.dialogWingsIDShow,
            callback: function($$v) {
              _vm.dialogWingsIDShow = $$v
            },
            expression: "dialogWingsIDShow"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                { attrs: { slot: "header", inverted: "" }, slot: "header" },
                [_c("q-toolbar-title", [_vm._v("Wings ID")])],
                1
              ),
              _c(
                "q-toolbar",
                { attrs: { slot: "header", inverted: "" }, slot: "header" },
                [
                  _c(
                    "q-card",
                    {
                      staticClass:
                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                    },
                    [
                      _c(
                        "q-card-main",
                        { staticClass: "column justify-center full-height" },
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass:
                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                              attrs: {
                                color: "primary-light",
                                "text-color": "primary",
                                rounded: ""
                              },
                              nativeOn: {
                                click: function($event) {
                                  _vm.dialogWingsIDShow = false
                                  _vm.soundPlay("tap")
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/statics/_demo/" +
                                    (_vm.anyDarkmode
                                      ? "chevron.compact.down_white.svg"
                                      : "chevron.compact.down_primary.svg"),
                                  height: "10"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "layout-padding" }, [
                _c("p", { staticClass: "text-justify" }, [
                  _vm._v("\n          Your "),
                  _c("strong", { staticClass: "text-value" }, [
                    _vm._v("Wings ID")
                  ]),
                  _vm._v(
                    " account is secured by a unique Digital ID Ethereum Blockchain address shown below.\n        "
                  )
                ]),
                _c("p", { staticClass: "text-justify" }, [
                  _vm._v(
                    "\n          This address acts like a virtual ID card that only you can access, protecting your account with secure and passwordless authentication method.\n        "
                  )
                ]),
                _c(
                  "p",
                  { staticClass: "text-fixedwidth-brand text-center" },
                  [
                    _vm.authenticated && _vm.$store.state.app.io.idx
                      ? _c(
                          "q-chip",
                          {
                            staticClass: "q-chip-subinfo",
                            attrs: { dense: "" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$store.state.app.io.idx.split(":")[2])
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("p", { staticClass: "text-center subtitle" }, [
                  _vm._v("Powered by our partner "),
                  _c(
                    "a",
                    { attrs: { href: "https://magic.link", target: "new" } },
                    [_vm._v("Magic")]
                  ),
                  _vm._v(".")
                ]),
                _c("img", {
                  staticClass: "block margin-auto-lr",
                  attrs: {
                    height: "50",
                    src:
                      "/statics/clients/magic.link/horiz/" +
                      (_vm.anyDarkmode
                        ? "2-Magic_Color_White.svg"
                        : "1-Magic_Color_BLACK.svg")
                  }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-modal",
        {
          attrs: {
            minimized: "",
            "no-esc-dismiss": "",
            "no-backdrop-dismiss": ""
          },
          model: {
            value: _vm.dialogUpdatesShow,
            callback: function($$v) {
              _vm.dialogUpdatesShow = $$v
            },
            expression: "dialogUpdatesShow"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                { attrs: { slot: "header", inverted: "" }, slot: "header" },
                [
                  _c("q-toolbar-title", [
                    _vm._v(_vm._s(_vm.$t("UPDATES.TITLE_CHECK")))
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "layout-padding" },
                [
                  _c("q-progress", {
                    attrs: {
                      indeterminate: "",
                      color: "secondary",
                      stripe: "",
                      animate: "",
                      height: "12px"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }